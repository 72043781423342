.background {
    background-image: url("https://ottoinsurance.com/assets/LP/theGiraffe/Giraffe_Background.png");
    height: 100vh;
    width: 100vw;
    background-color: #eaeceb;
    background-size: contain;
    background-position-x: 145%;
    background-position-y: 3.5rem;
    background-repeat: no-repeat;
}

.container {
    height: 100%;
    width: 100%;
    padding-left: 5rem;
    display: flex;
    flex-direction: column;
    font-weight: 400;
}

.logo-container {
    margin-top: 15px;
    flex: 1;
}

.logo {
    max-width: 25rem;
}

.page-title {
    flex: 2;
    display: flex;
    align-items: flex-end;
    color: #564b48;
    font-size: 2.25rem;
    padding-bottom: 1rem;
}

.ty {
    font-size: 1.5rem;
}

.cta-container {
    flex: 2;
    display: flex;
    align-items: flex-start;
    padding-top: 1rem;
    font-size: 1.15rem;
}

.cta-input {
    width: 25rem;
    font-size: 1rem;
    border: none;
    height: 3rem;
    padding-left: 14px;
}

.cta-button {
    margin-left: 8px;
    width: 12rem;
    font-weight: 700;
    background-color: #fbc77c;
    color: black;
    font-size: 1rem;
    border: none;
    height: 3rem;
    padding-left: 14px;
    cursor: pointer;
    transition: background-color ease-in-out 500ms, width ease-in-out 500ms;
}

.cta-input:focus {
    outline-color: #fbc77c;
}

.cta-button:focus {
    outline: none;
}

.cta-button:hover {
    background-color: #f4c18b;
    width: 12.5rem;
}

.footer {
    flex: 6;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: 300;
    color: black;
}

.footer>p {
    margin-top: 0;
}

.inline-links {
    display: inline-flex;
    align-items: center;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .background {
        background-position: bottom;
        background-position-x: center;
        background-position-y: 90%;
        text-align: center;
    }

    .container {
        padding-left: 0;
        align-items: center;
    }

    .logo-container {
        width: 90%;
    }

    .page-title {
        width: 90%;
        font-size: 2rem;
    }

    .cta-container {
        flex-direction: column;
        align-items: center;
        width: 90%;
    }

    .cta-input {
        text-align: center;
        width: 100%;
        padding-left: 0;
    }

    .cta-button {
        width: 100%;
        margin-left: 0;
        margin-top: 12px;
    }

    .footer {
        width: 90%;
        font-size: .75rem;
        padding-bottom: 10px;
    }

    .footer>p {
        margin: 0 5px;
    }
    .inline-links {
        justify-content: center;
    }
}
