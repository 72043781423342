.otto2-background {
    height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
    background-color: #ffffff;
    background-size: contain;
    background-position-x: 145%;
    background-position-y: 3.5rem;
    background-repeat: no-repeat;
}

.otto2-logo-container {
    margin-top: 15px;
    flex: 3;
}
.otto-paint {
    content: url("https://ottoinsurance.com/assets/LP/Otto/Otto.png");
    height: 5rem;
    display: flex;
    margin-left: -1rem;
    margin-bottom: 1rem;
}
.otto-slash-paint {
    content: url("https://ottoinsurance.com/assets/LP/Otto/Slash.png");
    height: 7px;
    display: flex;
    width: 155px;
    margin-bottom: -48px;
    z-index: 1000;
}

.otto2-main-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #072344;
    text-align: center;
    font-size: 70px;
    margin-top: 20px;
}

.otto2-main-title-text {
    color: #072344;
    font-size: 70px;
    margin: 0;
}

.otto2-logo {
    max-width: 12rem;
    margin-left: 2rem;
}

.otto2-footer {
    font-weight: 300;
    color: #535353;
    padding: 1.5rem;
    background-color: #ededed;
}

.otto-social-container {
    text-align: right;
}

ul.otto-footer-social{
    list-style: none;
    padding: 0;
    display: inline-flex;
}

ul.otto-footer-social li a{
    color: #535353;
}

ul.otto-footer-social li a:hover{
    opacity: .9;
}

.otto-footer-social-icon {
    width: 45px;
    height: 45px;
    margin-right: 1rem;
}

.otto2-inline-links {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.otto2-header-links {
    text-align: right;
}

.otto2-header-link {
    margin-right: 2rem;
    text-decoration: none;
    color: rgba(0,0,0,0.5);
}
.otto2-header-link:hover {
    text-decoration: underline;
}

.otto2-phone-link {
    margin-right: 2rem;
    color: #38445f;
    text-decoration: none;
}

.otto2-phone-link:hover {
    text-decoration: underline;
}

.otto2-phone-link-icon {
    margin-bottom: -5px;
    margin-right: 5px;
}

.otto2-insurance-icon-grid {
    padding: 2rem;
}

.otto2-insurance-icon-container {
    padding: 1rem 2rem;
    border: solid 2px rgba(0,0,0,0.15);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #072344;
    font-size: 25px;
}

.otto2-insurance-icon {
    width: 80%;
    margin-bottom: 1rem;
}

.otto2-insurance-icons {
    text-align: center;
}

.otto2-mascot {
    max-width: 40rem;
}

.webp .otto2-learn-more-container {
    background-image: url("https://ottoinsurance.com/assets/LP/Otto2/Otto_InsuranceBackground.webp");
}

.no-webp .otto2-learn-more-container {
    background-image: url("https://ottoinsurance.com/assets/LP/Otto2/Otto_InsuranceBackground.jpg");
}

.otto2-learn-more-container {
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 12px;
    min-height: 40rem;
    background-position: center;
}

.otto2-learn-more-heading {
    color: white;
    font-size: 55px;
    margin-top: -3rem;
    margin-bottom: 0;
    max-width: 30rem;
}

.otto2-learn-more-content {
    color: #fffc;
    font-size: 25px;
    max-width: 30rem;
}

.otto2-phone-form-container {
    min-height: 35rem;
    position: relative;
}

.otto2-form-image-1 {
    height: 30rem;
    position: absolute;
    z-index: 900;
    top: 0;
    left: 40px;
    box-shadow: 0 0 5px -3px;
    border-radius: 25px;
}

.otto2-form-image-2 {
    height: 30rem;
    position: absolute;
    z-index: 800;
    top: 20px;
    left: 120px;
    box-shadow: 0 0 5px -3px;
    border-radius: 25px;
}

.otto2-form-image-3 {
    height: 30rem;
    position: absolute;
    z-index: 700;
    top: 40px;
    left: 200px;
    box-shadow: 0 0 5px -3px;
    border-radius: 25px;
}

.otto2-compare-save-container {
    margin: 0 auto;
    max-width: 85rem;
}

.otto2-otto-money {
    max-width: 35rem;
    padding-left: 5rem;
}

.otto2-otto-phone-savings {
    /*position: absolute;*/
    /*right: 4rem;*/
    max-width: 25rem;
    /*top: 5rem;*/
}

.otto2-compare-image-container {
    margin-bottom: 3rem;
    text-align: center;
}

.otto2-compare-save-heading {
    color: black;
    font-size: 55px;
    margin-top: -3rem;
    margin-bottom: 0;
    max-width: 30rem;
}

.otto2-compare-save-content {
    color: rgba(0, 0, 0, 0.85);
    font-size: 23px;
    max-width: 35rem;
}

.otto2-why-choose-container {
    background: #00478B;
    color: white;
    padding: 2rem 0;
}

.otto2-choose-main-title {
    text-align: center;
    font-size: 50px;
    padding-bottom: 4rem;
    padding-top: 1rem;
    border-bottom: solid #a09760;
    margin: 0;
}

.otto2-choose-circle {
    max-width: 3rem;
}

.choose-icon-circle {
    text-align: center;
    border: solid #a09760;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: -3rem;
    background: #00478B;
}

.otto2-important-disclaimer {
    width: 90%;
    margin: 1rem auto;
    padding: 12px;
    text-align: center;
    font-size: 14px;
}

.why-choose-container {
    color: white;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.choose-content-container {
    max-width: 12rem;
    text-align: left;
    margin-top: 15px;
}

.choose-content {
    padding-left: 1rem;
    line-height: 22px;
}

.choose-content>li {
    margin-bottom: 1rem;
}

.choose-title {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.otto2-lets-see-container {
    margin: 3rem 0;
}

.otto2-lets-see-title {
    color: #072344;
    font-size: 50px;
    text-align: center;
    margin: 0;
    margin-bottom: 3rem;
}

.otto2-lets-see-link-container {
    text-align: center;
}

.otto2-lets-see-button-link {
    padding: 15px 50px;
    background-color: #edd776;
    text-decoration: none;
    color: black;
    font-size: 22px;
    font-weight: 500;
    border-bottom: solid #d2bc69;
    width: 200px;
    display: block;
    position: relative;
}

.otto2-lets-see-button-link::after {
    right: 1rem;
    bottom: 14px;
    content: '❯';
    position: absolute;
    text-align: center;
    color: black;
}

.otto2-email-link {
    text-decoration: none;
    font-weight: bolder;
}

.otto2-footer-insurance-links {
    display: inline-flex;
    margin-left: 5rem;
}

.otto2-footer-insurance-links > a {
    text-decoration: none;
    margin-right: 3rem;
    font-weight: 500;
    font-size: 20px;
}

.otto2-step {
    display: inline-flex;
}

.otto2-steps-container {
    border-bottom: dashed 4px #072344;
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding: 0 3rem;
}

.otto2-steps-number {
    font-size: 110px;
    color: #072344;
    font-weight: 900;
    margin-bottom: -3.5rem;
}

.bold-italic {
    font-weight: bold;
    font-style: italic;
    display: contents;
}

.otto2-steps-text {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 10px;
    font-size: 22px;
    padding-left: 5px;
    margin: 0;
}

.news-story-container {
    margin-bottom:3rem;
}

.news-story-container p {
    margin-top: 0;
    line-height: 1.5;
}

a.news-link-headline {
    margin: 0px 0 10px;
    font-size: 34px;
    font-weight: 700;
    color:#072344;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.35s;
}

a.news-read-more {
    font-weight: bold;
    color: #00478b;
    text-decoration: none;
    transition: all 0.35s;
}

a.news-link-headline:hover,
a.news-read-more:hover {
    color: rgba(0,0,0,0.5);
    text-decoration: none;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
    .hide-on-mobile {
        display: none;
    }

    .otto2-logo {
        max-width: 9rem;
        margin-left: 1rem;
    }

    .otto2-header-links {
        padding-top: 1rem;
    }

    .otto2-phone-link {
        margin-right: 1rem;
    }

    .otto2-main-title {
        font-size: 45px;
        margin-top: 30px;
    }

    .otto2-main-title-text {
        font-size: 45px;
        margin: 0;
    }

    .otto2-insurance-icon-grid {
        margin-top: 20px;
        padding: 8px;
    }

    .otto2-insurance-icon-container {
        font-size: 18px;
    }

    .otto2-insurance-icon {
        width: 100%;
    }

    .otto2-mascot {
        max-width: 100%;
    }
    .otto-paint {
        height: 4rem;
    }
    .otto-slash-paint {
        width: 98px;
        margin-bottom: -35px;
    }

    .otto2-learn-more-heading {
        font-size: 35px;
        padding-left: 15px;
        margin-top: 2rem;
        text-align: center;
    }

    .otto2-learn-more-content {
        padding-left: 15px;
        font-size: 22px;
        text-align: center;
    }

    .otto2-form-image-1 {
        height: 25rem;
        top: 35px;
    }

    .otto2-form-image-2 {
        height: 25rem;
        top: 70px;
        left: 100px;
    }

    .otto2-form-image-3 {
        height: 25rem;
        top: 105px;
        left: 160px;
    }

    .otto2-compare-save-heading {
        font-size: 35px;
        margin-top: 1rem;
        text-align: center;
    }

    .otto2-compare-save-content {
        padding-right: 15px;
        padding-left: 15px;
        font-size: 22px;
        text-align: center;
        margin-bottom: 3rem;
    }

    .otto2-compare-image-container {
        margin-top: 3rem;
    }

    .otto2-otto-money {
        max-width: 17rem;
        margin-left: -6rem;
    }

    .otto2-otto-phone-savings {
        max-width: 15rem;
        right: 0;
        top: 0;
    }

    .otto2-choose-main-title {
        margin: 0;
        font-size: 38px;
    }

    .choose-icon-circle-mobile-margin {
        margin-top: 0;
    }

    .choose-content-container {
        max-width: 90%;
    }

    .choose-content {
        font-size: 18px;
    }

    .otto2-lets-see-title {
        font-size: 38px;
    }

    .otto2-lets-see-link-container {
        display: inline-flex;
        justify-content: center;
        margin-bottom: 1.5rem;
    }

    .otto2-lets-see-container {
        margin-bottom: 0;
    }

    .otto2-steps-container {
        margin-top: 1rem;
        padding-top: 1rem;
        margin-bottom: 1rem;
        border-bottom: none;
        border-top: dashed 4px #072344;
    }

    .otto2-steps-text {
        align-items: center;
        margin: 0;
        padding-left: 12px;
    }

    .otto2-steps-number {
        margin-bottom: 0;
    }

    .otto2-inline-links {
        flex-direction: column;
    }

    .otto2-footer {
        text-align: center;
    }

    .otto2-inline-links>p {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 2rem;
    }

    .otto2-inline-links-container {
        font-size: 14px;
        text-align: center;
        margin-bottom: 2rem;
    }

    .otto2-footer-insurance-links {
        margin-left: 0;
        display: inline-flex;
        flex-direction: column;
    }

    .otto2-footer-insurance-links>a {
        margin-right: 0;
    }
    .otto-social-container{
        text-align: center;
    }
    .otto2-important-disclaimer {
        margin: 1rem auto;
        padding: 0 12px;
        width: 90%;
    }
}
